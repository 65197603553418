export const toDate = (date: any): Date => {
  if (typeof date === 'string') {
    return new Date(date)
  }

  if (typeof date === 'object') {
    if ('seconds' in date) {
      return new Date(date.seconds * 1000)
    }
    return new Date(date)
  }
  return date
}